<template>
    <section class="section2" id="skills">
        <div class=" flex container sectionTitle my-10 text-2xl text-black"><span>______</span>Skills</div>
        <div class=" flex flex-row margin-box flex-wrap justify-center">
<div class="h-44 w-32 bg-gray-300 rounded-xl flex flex-col justify-center shadow duration-300 hover:bg-blue-500 hover:shadow-xl hover:text-white hover:fill-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 18.178l-4.62-1.256-.328-3.544h2.27l.158 1.844 2.52.667 2.52-.667.26-2.866H6.96l-.635-6.678h11.35l-.227 2.21H8.822l.204 2.256h8.217l-.624 6.778L12 18.178zM3 2h18l-1.623 18L12 22l-7.377-2L3 2zm2.188 2L6.49 18.434 12 19.928l5.51-1.494L18.812 4H5.188z"/></svg>
    <span class="mt-6 mb-6 text-sm ?leading-5 font-semibold text-center">HTML / CSS</span>
</div>
<div class="h-44 w-32 bg-gray-300 rounded-xl flex flex-col justify-center shadow duration-300 hover:bg-blue-500 hover:shadow-xl hover:text-white hover:fill-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.316 3L12 18l8.684-15H23L12 22 1 3h2.316zm4.342 0L12 10.5 16.342 3h2.316L12 14.5 5.342 3h2.316z"/></svg>
    <span class="mt-6 mb-6 text-sm ?leading-5 font-semibold text-center">Vue.js</span>
</div>
<div class="h-44 w-32 bg-gray-300 rounded-xl flex flex-col justify-center shadow duration-300 hover:bg-blue-500 hover:shadow-xl hover:text-white hover:fill-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M4 18v-3.7a1.5 1.5 0 0 0-1.5-1.5H2v-1.6h.5A1.5 1.5 0 0 0 4 9.7V6a3 3 0 0 1 3-3h1v2H7a1 1 0 0 0-1 1v4.1A2 2 0 0 1 4.626 12 2 2 0 0 1 6 13.9V18a1 1 0 0 0 1 1h1v2H7a3 3 0 0 1-3-3zm16-3.7V18a3 3 0 0 1-3 3h-1v-2h1a1 1 0 0 0 1-1v-4.1a2 2 0 0 1 1.374-1.9A2 2 0 0 1 18 10.1V6a1 1 0 0 0-1-1h-1V3h1a3 3 0 0 1 3 3v3.7a1.5 1.5 0 0 0 1.5 1.5h.5v1.6h-.5a1.5 1.5 0 0 0-1.5 1.5z"/></svg>
    <span class="mt-6 mb-6 text-sm ?leading-5 font-semibold text-center">JavaScript</span>
</div>
<div class="h-44 w-32 bg-gray-300 rounded-xl flex flex-col justify-center shadow duration-300 hover:bg-blue-500 hover:shadow-xl hover:text-white hover:fill-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M5 12.5c0 .313.461.858 1.53 1.393C7.914 14.585 9.877 15 12 15c2.123 0 4.086-.415 5.47-1.107 1.069-.535 1.53-1.08 1.53-1.393v-2.171C17.35 11.349 14.827 12 12 12s-5.35-.652-7-1.671V12.5zm14 2.829C17.35 16.349 14.827 17 12 17s-5.35-.652-7-1.671V17.5c0 .313.461.858 1.53 1.393C7.914 19.585 9.877 20 12 20c2.123 0 4.086-.415 5.47-1.107 1.069-.535 1.53-1.08 1.53-1.393v-2.171zM3 17.5v-10C3 5.015 7.03 3 12 3s9 2.015 9 4.5v10c0 2.485-4.03 4.5-9 4.5s-9-2.015-9-4.5zm9-7.5c2.123 0 4.086-.415 5.47-1.107C18.539 8.358 19 7.813 19 7.5c0-.313-.461-.858-1.53-1.393C16.086 5.415 14.123 5 12 5c-2.123 0-4.086.415-5.47 1.107C5.461 6.642 5 7.187 5 7.5c0 .313.461.858 1.53 1.393C7.914 9.585 9.877 10 12 10z"/></svg>
    <span class="mt-6 mb-6 text-sm ?leading-5 font-semibold text-center">SQL</span>
</div>
<div class="h-44 w-32 bg-gray-300 rounded-xl flex flex-col justify-center shadow duration-300 hover:bg-blue-500 hover:shadow-xl hover:text-white hover:fill-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0H24V24H0z"/><path d="M21 2.5v19l-18-2v-15l18-2zm-2 10.499L12 13v5.487l7 .778V13zm-14 4.71l5 .556V13l-5-.001v4.71zM19 11V4.735l-7 .777V11l7-.001zm-9-5.265L5 6.29V11L10 11V5.734z"/></svg>
    <span class="mt-6 mb-6 text-sm ?leading-5 font-semibold text-center">Microsoft Office</span>
</div>
<div class="h-44 w-32 bg-gray-300 rounded-xl flex flex-col justify-center shadow duration-300 hover:bg-blue-500 hover:shadow-xl hover:text-white hover:fill-white">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"/></svg>
    <span class="mt-6 mb-6 text-sm ?leading-5 font-semibold text-center">LAN / WLAN</span>
</div>

</div>
</section>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style>
.section2{
    background-color: white;
    padding: 2rem 0;
}
.margin-box{
    margin:35px;
}
.margin-box div{
    margin:20px;
}
.margin-box div svg{
    display: block;
    margin:auto;
}

</style>