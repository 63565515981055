<template>
    <section class="section1" id="portfolio">
      <div class=" flex container sectionTitle my-10 text-2xl text-white"><span>______</span>Projects</div>
        <div class="container flex flex-wrap justify-center gap-8">
        <div class="w-96 rounded-lg bg-gray-100">
    <img
      src="@/assets/szaf.png"
      alt=""
      class="h-48 w-full rounded-t-lg opacity-80 transition duration-300 hover:opacity-100 sm:h-56"
    />

    <div class="mb-10 px-10 py-6 text-center">
      <div class="mb-4 text-3xl font-bold uppercase text-black">SzafirowaWyspa</div>
      <span class="text-sm">
       Website made in WordPress, designed by myself
      </span>
    </div>
   <a href="http://szafirowawyspa.com/" target="_blank"> <button
      class="h-16 w-full rounded-b-lg bg-blue-600 text-lg font-extrabold text-gray-100 transition duration-300 hover:bg-blue-900"
    >
      View site
    </button></a>
  </div>
        <div class="w-96 rounded-lg bg-gray-100">
    <img
      src="@/assets/monster.png"
      alt=""
      class="h-48 w-full rounded-t-lg opacity-80 transition duration-300 hover:opacity-100 sm:h-56"
    />

    <div class="mb-10 px-10 py-6 text-center">
      <div class="mb-4 text-3xl font-bold uppercase text-black">Monster Slash</div>
      <span class="text-sm">
       Simple game created with Vue.js, some basic functions and directives
      </span>
    </div>
   <a href="https://github.com/skorzeniak/fun-with-vue/tree/main/game" target="_blank"> <button
      class="h-16 w-full rounded-b-lg bg-blue-600 text-lg font-extrabold text-gray-100 transition duration-300 hover:bg-blue-900"
    >
      View site
    </button></a>
  </div>
        <div class="w-96 rounded-lg bg-gray-100">
    <img
      src="#"
      alt=""
      class="h-48 w-full rounded-t-lg opacity-80 transition duration-300 hover:opacity-100 sm:h-56"
    />

    <div class="mb-10 px-10 py-6 text-center">
      <div class="mb-4 text-3xl font-bold uppercase text-black">Commin soon</div>
      <span class="text-sm">
       Ill add more projects in future, when my github will be update
      </span>
    </div>
   <a href="#"> <button
      class="h-16 w-full rounded-b-lg bg-blue-600 text-lg font-extrabold text-gray-100 transition duration-300 hover:bg-blue-900"
    >
      Soon
    </button></a>
  </div>
</div>

    </section>
</template>


<script>
export default {
    setup() {
        
    },
}
</script>


<style>

</style>