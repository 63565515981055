<template>
    <section class="section2" id="contact">
        <div class=" flex container sectionTitle my-10 text-2xl text-black"><span>______</span>Contact</div>
        <div class="container flex flex-col flex-wrap justify-start lg:justify-center lg:flex-row">
        <div class="flex flex-col flex-wrap justify-start lg:justify-center w-full lg:flex-row">
          <div class="flex justify-start lg:justify-center mb-12 basis-1/3 ">
            <div class="flex items-start">
              <div class="shrink-0">
                <div class="p-4 bg-blue-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z"/></svg>
                </div>
              </div>
              <div class="grow ml-6">
                <p class="font-bold mb-1">Mail</p>
                <p class="text-gray-500"><a href="mailto:s.korzeniak@gmail.com">s.korzeniak@gmail.com</a>  </p>
        
              </div>
            </div>
          </div>
          <div class="flex justify-start lg:justify-center mb-12 basis-1/3">
            <div class="flex items-start">
              <div class="shrink-0">
                <div class="p-4 bg-blue-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 9.55C12.917 8.613 14.111 8 15.5 8a5.5 5.5 0 0 1 5.5 5.5V21h-2v-7.5a3.5 3.5 0 0 0-7 0V21h-2V8.5h2v1.05zM5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-1 2h2V21H4V8.5z"/></svg>
                </div>
              </div>
              <div class="grow ml-6">
                <p class="font-bold mb-1">LinkedIn</p>
                <p class="text-gray-500"><a href="https://linkedin.com/in/sebastian-korzeniak-93992a107" target="_blank">MSG LinkedIn</a></p>

              </div>
            </div>
          </div>
          <div class=" flex justify-start lg:justify-center mb-12 basis-1/3">
            <div class="flex align-start">
              <div class="shrink-0">
                <div class="p-4 bg-blue-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bug" class="w-5 text-white"
                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M511.988 288.9c-.478 17.43-15.217 31.1-32.653 31.1H424v16c0 21.864-4.882 42.584-13.6 61.145l60.228 60.228c12.496 12.497 12.496 32.758 0 45.255-12.498 12.497-32.759 12.496-45.256 0l-54.736-54.736C345.886 467.965 314.351 480 280 480V236c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v244c-34.351 0-65.886-12.035-90.636-32.108l-54.736 54.736c-12.498 12.497-32.759 12.496-45.256 0-12.496-12.497-12.496-32.758 0-45.255l60.228-60.228C92.882 378.584 88 357.864 88 336v-16H32.666C15.23 320 .491 306.33.013 288.9-.484 270.816 14.028 256 32 256h56v-58.745l-46.628-46.628c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0L141.255 160h229.489l54.627-54.627c12.498-12.497 32.758-12.497 45.256 0 12.496 12.497 12.496 32.758 0 45.255L424 197.255V256h56c17.972 0 32.484 14.816 31.988 32.9zM257 0c-61.856 0-112 50.144-112 112h224C369 50.144 318.856 0 257 0z">
                    </path>
                  </svg>
                </div>
              </div>
              <div class="grow ml-6">
                <p class="font-bold mb-1">Facebook</p>
                <p class="text-gray-500"><a href="https://m.me/sebastian.korzeniak" target="_blank">MSG Facebook</a></p>
               
              </div>
            </div>
          </div>
        </div>
      </div>




    </section>
    </template>



<script>
export default {
    setup() {
        
    },
}
</script>


<style>

</style>